<template>
  <div class="content-wrapper">
    <div class="attendance-model">
      <el-dialog
      title="Attendance"
      :visible.sync="dialogVisible"
      width="30%">
      <div class="dialog-content">

        <div v-if="attendanceStatus === 'not_attended'">You are going to clock in.</div>
        <span v-if="attendanceStatus === 'not_attended'">Clock in date / time:</span>

        <div v-if="attendanceStatus !== 'not_attended'">You are going to clock out.</div>
        <span v-if="attendanceStatus !== 'not_attended'">Clock out date / time:</span>

        <span><strong>{{ getCurrentDate() }}</strong></span>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button v-if="attendanceStatus === 'not_attended'" type="primary" @click="clockIn">Confirm</el-button>
          <el-button v-if="attendanceStatus !== 'not_attended'" type="primary" @click="clockOut">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
    </div>

    <div class="employees main-content">

      <div class="page-header">
        <h1>My Attendance</h1>
        <div class="card status-card" v-if="attendanceStatus === 'off_day'">
          <div class="attendance-status">
            <span> Today is day off.</span>
          </div>
          <div class="workshift-list" v-if="workshifts.length !== 0">
            Your future workshift:
            <ul>
              <li v-for="workshift in workshifts">
                {{ getWorkshiftDateTime(workshift).date }}: {{ getWorkshiftDateTime(workshift).start_time }} -  {{ getWorkshiftDateTime(workshift).end_time }}
              </li>
            </ul>
          </div>
        </div>

        <div class="card status-card" v-if="attendanceStatus !== 'off_day'">
          <div class="attendance-status">
            <span>You had a workshift on today.</span>
          </div>
          <div class="attendance-status">
            <span>Your Attendance Status (Today):</span>
            <span class="status-text">{{ getStatusText(attendanceStatus) }}</span>
          </div>
          <div class="clockout-status">
            <span>Your Clock Out Status (Today):</span>
            <span class="status-text">{{ getStatusText(clockOutStatus) }}</span>
          </div>
        </div>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Reference ID" :advancedSearch="false" @search="searchRecord" @reset="resetFilter" />
        <div class="tool">
          <button class="btn btn-primary" @click="showDialog" v-if="attendanceStatus === 'not_attended'">
            <i class="el-icon-s-claim"></i>
            Clock In
          </button>
          <button class="btn btn-primary" @click="showDialog" v-if="attendanceStatus !== 'not_attended' && attendanceStatus !== 'off_day' &&
          attendanceStatus !== 'leave'">
            <i class="el-icon-s-home"></i>
            Clock Out
          </button>
        </div>
      </div>

      <div v-if="hadError" class="error-meesages">
        You had non-attendance record which had not apply the leave application ! Please contact with HR ASAR. 
      </div>

      <div class="employee-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Reference ID</th>
              <th scope="col">Date</th>
              <th scope="col">Start Time</th>
              <th scope="col">End Time</th>
              <th scope="col">Clock In Status</th>
              <th scope="col">Clock Out Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in records">
              <th scope="row">{{record.reference_id}}</th>
              <td v-if="record.date">{{formatDateTime(record.date, 'YYYY/MM/DD')}}</td>
              <td v-else></td>
              <td v-if="record.start_time">{{formatDateTime(record.start_time, 'H:mm:ss')}}</td>
              <td v-else></td>
              <td v-if="record.end_time">{{formatDateTime(record.end_time, 'H:mm:ss')}}</td>
              <td v-else></td>
              <td>{{ getStatusText(record.clock_in_status) }}</td>
              <td>{{ getStatusText(record.clock_out_status) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'MyAttendance',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {},
      records: [],
      workshifts: [],
      originRecords: [],
      latestRecord: null,
      dialogVisible: false,
      attendanceStatus: null,
      clockOutStatus: null,
      hadError: false,
    }
  },
  mounted(){
    this.loadUserWorkshift();
    this.loadUserAttendance();
    this.checkUserAttendanceStatus();
  },
  methods:{
    showDialog(){
      this.dialogVisible = true;
    },
    closeDialog(){
      this.dialogVisible = false;
    },
    getCurrentDate(){
      const date = Common.getCurrentDate('YYYY/MM/DD H:mm:ss');
      return date;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async loadUserWorkshift(){
      const loginInfo = Common.getLoginInfo();
      const currentDate = dayjs();
      let workshifts = await Hr.loadUserWorkshift(this.apiUrl, loginInfo);
      workshifts = workshifts.filter(function(workshift){
        const workshiftDate = dayjs.unix(workshift.date);
        if(workshiftDate.isSameOrAfter(currentDate)){
          return true;
        }
      });
      this.workshifts = workshifts;
    },
    getWorkshiftDateTime(workshift){
      const dateTime = {
        'date': dayjs.unix(workshift.date).format('YYYY/MM/DD'),
        'start_time': dayjs.unix(workshift.start_time).format('H:mm'),
        'end_time': dayjs.unix(workshift.end_time).format('H:mm'),
      };
      return dateTime;
    },
    async clockIn(){
      const loginInfo = Common.getLoginInfo();
      const currentTimestamp = Common.getCurrentTimestamp();
      const workshiftId = this.latestRecord.workshift_id;
      const recordId = this.latestRecord.id;
      try{
        const attendanceRecord = await Hr.clockIn(this.apiUrl, recordId, workshiftId, loginInfo);
        const targetRecord = this.records.findIndex(item => item.id == this.latestRecord.id);
        this.records[targetRecord] = attendanceRecord;
        this.attendanceStatus = attendanceRecord['clock_in_status'];
        this.dialogVisible = false;
        this.latestRecord = attendanceRecord;

      }catch(err){
        console.log(err);
      }
    },
    async clockOut(){
      const loginInfo = Common.getLoginInfo();
      const recordId = this.latestRecord.id;
      const workshiftId = this.latestRecord.workshift_id;
      try{
        const attendanceRecord = await Hr.clockOut(this.apiUrl, recordId, workshiftId, loginInfo);
        const targetRecord = this.records.findIndex(item => item.id == this.latestRecord.id);
        this.records[targetRecord] = attendanceRecord;
        this.dialogVisible = false;
        this.clockOutStatus = attendanceRecord['clock_out_status'];

      }catch(err){
        console.log(err);
      }
    },
    async checkUserAttendanceStatus(){
      const loginInfo = Common.getLoginInfo();
      const attendanceRecords = await Hr.loadUserAttendance(this.apiUrl, loginInfo);
      const latestRecord = attendanceRecords.find(function(record){
        //Get today record only
        const todayStart = dayjs().startOf('date');
        const recordDate = dayjs.unix(record['date']);
        if(recordDate.isSame(todayStart)){
          return true;
        }
      });
      if(latestRecord){
        const clockInStatus = latestRecord.clock_in_status;
        this.attendanceStatus = clockInStatus;

        const clockOutStatus = latestRecord.clock_out_status;
        this.clockOutStatus = clockOutStatus;

        this.latestRecord = latestRecord;
      }else{
        this.attendanceStatus = 'off_day';
      }
    },
    async loadUserAttendance(){
      const loginInfo = Common.getLoginInfo();
      try{
        const attendanceRecords = await Hr.loadUserAttendance(this.apiUrl, loginInfo);
        for (let i = 0; i < attendanceRecords.length; i++){
          const attendanceRecord = attendanceRecords[i];
          if(attendanceRecord.clock_in_status === "not_attended"){
            this.hadError = true;
          }
        }
        this.originRecords = attendanceRecords;
        this.records = attendanceRecords;
      }catch(err){
        console.log(err);
      }
    },
    searchRecord(textSearchValue){
      this.searchForm['reference_id'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newRecords = Common.filterItems(searchFilter, this.originRecords);
      this.records = newRecords;
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.records = this.originRecords;
    },
    getStatusText(status){
      const statusList = {
        'at_work': 'Attended',
        'clock_out': 'Clock out',
        'leave_early': 'Leave Early',
        'late': 'Late',
        'ot': 'OT',
        'normal': 'Clock Out',
        'absenteeism': 'Absenteeism',
        'leave': 'Sick Leave / Annual Leave',
        'not_attended': 'Not Attended',
      };
      const statusText = statusList[status];
      return statusText;
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.error-meesages{
  color: red;
  margin-bottom: 20px;
}
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.employee-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}

.status-card{
  margin-top: 50px;
  padding-bottom: 20px;
}
.attendance-status, .clockout-status, .worktime-status{
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 20px;

  .status-text{
    margin-left: 5px;
    font-weight: bold;
  }
}

.workshift-list{
  padding-left: 20px;
  padding-top: 20px;

  ul{
    padding: 15px;
  }
}
.dialog-content{
  font-size: 1.2em;
}
.dialog-footer{
  margin-top: 30px;
}
</style>
